<template>
  <div class="cop-body">
    <TheNav />

    <div class="content">
      <div class="alert">
        <p>
          <i class="ri-chat-smile-3-fill"></i>说明：以下课程由 doyoudo
          与合作方联合制作，但内容属于合作方，并且只由合作方平台负责出售、播放、售后，而不是在
          doyoudo 哦。
        </p>
      </div>

      <div class="card">
        <div class="left">
          <img
            src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/smwe5eloffhgj7.jpeg"
            alt="1超能力"
          />
        </div>
        <div class="right">
          <p class="who">doyoudo<i class="ri-service-fill"></i>哔哩哔哩</p>
          <p class="title">C4D大作战：百万UP的光之教程</p>
          <p class="author">
            <i class="ri-user-smile-line"></i>doyoudo小莫老师
          </p>

          <a href="https://www.bilibili.com/cheese/play/ss6298" target="_blank"
            ><button>查看详情</button></a
          >
        </div>
      </div>

      <div class="card">
          <div class="left">
            <img
              src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/l53p2bal0hs4.jpg"
              alt="1超能力"
            />
          </div>
          <div class="right">
            <p class="who">doyoudo<i class="ri-service-fill"></i>哔哩哔哩</p>
            <p class="title">C4D大作战：百万UP的光之教程</p>
            <p class="author">
              <i class="ri-user-smile-line"></i>doyoudo小莫老师
            </p>

            <a href="https://www.bilibili.com/cheese/play/ep6847" target="_blank"
              ><button>查看详情</button></a
            >
          </div>
        </div>

      <div class="card">
        <div class="left">
          <img
            src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/l0midk0i0bz1.jpg"
            alt="1超能力"
          />
        </div>
        <div class="right">
          <p class="who">doyoudo<i class="ri-service-fill"></i>哔哩哔哩</p>
          <p class="title">AE超能力学院-入门到精通</p>
          <p class="author">
            <i class="ri-user-smile-line"></i>doyoudo小莫老师
          </p>

          <a href="https://www.bilibili.com/cheese/play/ss84" target="_blank"
            ><button>查看详情</button></a
          >
        </div>
      </div>
      
      <p class="height"></p>
    </div>
  </div>

  <TheFooter />
</template>

<script>
import resource from "../assets/resource.json";
import { onMounted, ref, computed } from "vue";
import TheFooter from "../views/TheFooter.vue";
import TheNav from "../views/TheNav.vue";
export default {
  setup() {
    components: {
      TheFooter, TheNav;
    }

    return {
      TheFooter,
      TheNav,
    };
  },
};
</script>

<style scoped>
.cop-body {
  width: 100%;
  min-height: 800px;
  background: #f5f6f9f8;
}
.content {
  width: 900px;
  margin: 0 auto;
}
.alert {
  padding: 20px 30px;
  background: #fef6ed;
  border: #f5cd84 solid 1px;
  border-radius: 9px;
  margin-bottom: 30px;
  font-size: 0.9em;
}
.alert p {
  font-size: 1em;
  color: #46330a;
}
.alert i {
  color: #46330a;
  font-size: 1.1em;
  margin-right: 3px;
  vertical-align: text-bottom;
}

.card {
  background: #fff;
  padding: 15px;
  border-radius: 9px;
  display: flex;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
}
.card img {
  width: 100%;
}
.left {
  width: 60%;
}
.right {
  width: 39.9%;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right p {
  margin-bottom: 15px;
}
.right i {
  vertical-align: middle;
}
.ri-service-fill {
  margin: 0 5px;
  color: #f54611;
  vertical-align: top;
}

.ri-user-smile-line {
  margin-right: 5px;
  font-size: 1.3em;
  color: rgb(95, 95, 95);
}
.title {
  font-size: 1.25em;
  font-weight: bold;
  flex-grow: 0;
}
.author {
  font-size: 0.9em;
  flex-grow: 3;
  color: rgb(95, 95, 95);
}
.right button {
  color: #fff;
  border: none;
  background: #a2c217;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
}
.right button:hover {
  background: #94b113;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.09);
}

.height {
  height: 120px;
}

@media screen and (max-width: 470px) {
  .content {
    width: 95%;
  }

  .card {
    width: 100%;
    height: 95px;
    margin: 10px 0px;
    padding: 15px;
  }
  .card .logo {
    width: 10%;
  }
  .slogan {
    width: 100%;
    height: 100px;
    padding-top: 10px;
    text-align: center;
  }
  .slogan img {
    width: 95%;
  }

  .tabs button {
    font-size: 0.7em;
  }
}

@media screen and (min-width: 471px) and (max-width: 1024px) {
  .content {
    width: 95%;
  }

  .card {
    width: 45%;
    height: 95px;
    margin: 10px 17px;
    padding: 15px;
  }
  .card .logo {
    width: 10%;
  }
  .slogan {
    width: 90%;
    height: 130px;
    padding-top: 10px;
    text-align: center;
  }
  .slogan img {
    width: 95%;
  }

  .tabs button {
    font-size: 0.8em;
  }
}
</style>